import React from 'react';
import { connect } from 'react-redux';
import Img from "gatsby-image";
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { addToWishlist, removeFromWishlist } from '../../../app/actions';
import { withStyles } from '@material-ui/styles';
import styles from './Products.styles';
import ProductCard from './ProductCard';

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: item => dispatch(addToWishlist(item)),
  removeFromWishlist: item => dispatch(removeFromWishlist(item))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ProductsGrid extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.wishlist !== this.props.wishlist) {
      Cookies.set('wishlist', this.props.wishlist);
    }
  }

  wishlistHandler(id) {
    const { wishlist, addToWishlist, removeFromWishlist } = this.props;

    if (wishlist.includes(id)) {
      removeFromWishlist(id);
    } else {
      addToWishlist(id);
    }
  }

  render() {
    const { classes, products, wishlist } = this.props;

    if (!products) {
      return <div>No products</div>;
    }

    return <Grid container spacing={3} className={"product-grid " + classes.productGrid}>
      {products && products.map((product, index) => {
        if (!product) return null;
        const { id, link, brands, title } = product;
        let wishClass = "";

        if(wishlist.includes(String(product.product.code))) { wishClass = 'added'}

        if (product.product.variations) {
          product.product.variations.forEach(variation => {
            if (wishlist.includes(variation.variantCode)) {
              wishClass = 'added';
            }
          })
        }

        let image = null;

        if (product.featuredImage) {
          image = product.featuredImage.node.sourceUrl;
        }

        return <ProductCard
          key={id}
          code={product.product.code}
          id={id}
          link={link}
          brands={brands}
          title={title}
          description={product.product.shortDescription}
          wishClass={wishClass}
          wishlistHandler={id => this.wishlistHandler(id)}>
          {product.product.productGallery && <Img fluid={product.product.productGallery[0].localFile.childImageSharp.fluid} />}
          {!product.product.productGallery && image &&
            <img src={image} alt="" className="product-image"/>
          }
        </ProductCard>
    })}</Grid>;
  }
}

export default withStyles(styles)(connector(ProductsGrid));